<template>
  <v-app>
    <v-navigation-drawer v-if="$root.isAuthenticated" v-model="$root.appDrawer" mobile-breakpoint="1023"
      class="secondary no-print" dark app>
      <template v-slot:prepend>
        <v-list>
          <v-list-item two-line>
            <v-list-item-avatar v-if="$root.profilePhoto">
              <img :src=$root.profilePhoto />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="h5">{{ $root.myName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item v-for="item in mainWithPermission" :key="item.title" @click.stop="title = item.title"
          :to="item.path == '#' ? '' : item.path">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item>
        <v-list-group v-if="organizations.length && $root.myPermissions.includes('CanReadOrganizations')" no-action
          prepend-icon="location_city">
          <v-list-item slot="activator">
            <v-list-item-title>Organisaties</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="item in organizations" :key="item.title" @click.stop="title = item.title"
            :to="item.path == '#' ? '' : item.path">
            <v-list-item-title v-text="item.title"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-divider light></v-divider>
        <v-list-group v-if="managementWithPermission.length" no-action prepend-icon="edit">
          <v-list-item slot="activator">
            <v-list-item-title>Beheer</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="item in managementWithPermission" :key="item.title"
            @click.stop="title = 'Beheer ' + item.title" :to="item.path == '#' ? '' : item.path">
            <v-list-item-title v-text="item.title"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-divider light></v-divider>
        <v-list-group v-if="$root.isAdmin" no-action prepend-icon="settings">
          <v-list-item slot="activator">
            <v-list-item-title>Geavanceerd</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="item in advancedWithPermission" :key="item.title"
            @click.stop="title = 'Geavanceerd ' + item.title" :to="item.path == '#' ? '' : item.path">
            <v-list-item-title v-text="item.title"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-divider light></v-divider>
        <v-list-group v-if="$root.myPermissions.includes('CanAddHourBookings')" no-action prepend-icon="bar_chart">
          <v-list-item slot="activator">
            <v-list-item-title>Rapporten</v-list-item-title>
          </v-list-item>
          <v-list-item v-for="item in reportsWithPermission" :key="item.title"
            @click.stop="title = 'Rapport ' + item.title" :to="item.path == '#' ? '' : item.path">
            <v-list-item-title v-text="item.title"></v-list-item-title>
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
          </v-list-item>
        </v-list-group>
        <v-divider light></v-divider>
        <v-list-item @click.stop="title = 'Voorkeuren'" to="/preferences">
          <v-list-item-icon>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Voorkeuren</v-list-item-title>
        </v-list-item>
        <v-list-item @click.stop="logOut">
          <v-list-item-icon>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Uitloggen</v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-dialog v-model="showPermissions" width="350">
          <template v-slot:activator="{ on, attrs }">
            <div class="pa-2 white--text text-caption text-right" v-bind="attrs" v-on="on">
              versie {{ $version }}
            </div>
          </template>
          <v-card>
            <v-card-text>
              <div class="pa-2">huidige viewport: {{ $vuetify.breakpoint.name }}</div>
              <v-subheader>Permissies</v-subheader>
              <v-list v-if="showPermissions" dense>
                <v-list-item-group>
                  <v-list-item v-for="(item, index) in $root.myPermissions" :key="index">
                    <v-list-item-title>{{ item }}</v-list-item-title>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-subheader>Meldingcategorieën</v-subheader>
              <v-list v-if="showPermissions" dense>
                <v-list-item-group>
                  <v-list-item v-for="(item, index) in $root.myScPermissions" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{ item.canCreate ? 'CanCreate' : '' }} {{ item.canReadWrite ? ', CanReadWrite' : '' }}
                        {{ item.canManage ?
                          ', CanManage' : '' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
      </template>
    </v-navigation-drawer>
    <v-app-bar fixed app dark color="primary" class="no-print" :clipped-left="!$root.appDrawer" clipped-right>
      <v-app-bar-nav-icon @click.stop="$root.appDrawer = !$root.appDrawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog v-if="canCreateTickets" v-model="ticketDialog" persistent transition="dialog-top-transition"
        max-width="600">
        <template v-slot:activator="{ on, attrs }">
          <v-hover v-slot="{ hover }">
            <v-btn :class="(hover ? '' : 'pr-0')" class="mr-2" color="success" small v-bind="attrs" v-on="on">
              <v-icon left>
                mdi-bell-plus
              </v-icon>
              <span v-if="hover">nieuwe melding aanmaken</span>
            </v-btn>
          </v-hover>
        </template>
        <TicketForm v-if="ticketDialog" v-on:close="ticketDialog = false" v-on:done="newTicket"></TicketForm>
      </v-dialog>
      <NotificationListCard v-if="$root.isAuthenticated && $root.me" />
      <v-img class="ml-4" max-width="150" src="/SWARCO_Logo_white.png" lazy-src="/SWARCO_Logo_white.png">
      </v-img>
    </v-app-bar>
    <v-main class="backdrop">
      <v-container fluid>
        <ConfirmationDialog v-model="confirmDialog" width="600px" :persistent="true" :title="dialogConfirm?.title"
          :message="dialogConfirm?.message" :type="dialogConfirm?.type" @agree="agreeConfirmDialog" @close="closeConfirmDialog" />
        <v-dialog v-model="errorDialog" outlined persistent max-width="500">
          <v-card>
            <v-card-title>Error</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">Er is iets mis gegaan..</v-col>
                <v-col cols="12">Code: {{ $root.dialogErrorCode }}</v-col>
                <v-col cols="12">Details: {{ $root.dialogMessage }}</v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$root.clearDialog()">sluiten</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="infoDialog" outlined persistent max-width="500">
          <v-card>
            <v-card-title>Info</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">{{ $root.dialogMessage }}</v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="$root.clearDialog()">sluiten</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <router-view v-if="$root.isAuthenticated && $root.me"></router-view>
        <template v-if="!$root.isAuthenticated">
          <h1>Inloggen</h1>
          De sessie is verlopen, klik <v-btn small color="primary" @click="window.location.reload()">hier</v-btn> om
          opnieuw in te loggen
        </template>
        <template v-if="$root.isAuthenticated && !$root.me">
          <h1>Bezig met laden..</h1>
        </template>
      </v-container>
    </v-main>
    <v-snackbar v-model="showTokenAlert" timeout="-1" color='warning'>
      <span>De huidige sessie verloopt over <strong>{{ $moment(tokenExpirationTimer).format('mm:ss') }}</strong></span>
      <template v-slot:action>
        <v-btn small color="success" @click="renewToken()">vernieuw</v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="tokenExpired" persistent max-width="500">
      <v-card>
        <v-card-title>Sessie verlopen</v-card-title>
        <v-card-text>
          De huidige sessie is verlopen!
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="success" @click="renewToken()">vernieuw sessie</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<style>
.v-data-table tbody tr.v-data-table__expanded__content {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.v-data-table-header th.text-start.sortable {
  white-space: nowrap;
}

.expandtable tbody,
.expandtable thead {
  background-color: rgba(0, 0, 0, .15);
}

.small-chip {
  padding: 0 8px !important;
  height: 18px !important;
}

@media print {
  nav {
    display: none !important
  }

  header {
    display: none !important
  }

  main {
    padding: 0 !important
  }

  button {
    display: none !important
  }

  .no-print {
    display: none !important
  }

  .container {
    padding: 0 !important
  }

  .theme--dark.application,
  .theme--dark.v-table,
  .theme--dark.v-table thead th,
  .theme--dark.v-table tbody tr:hover:not(.v-datatable__expand-row) {
    background: white !important;
    color: black !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  div[role=listitem] {
    border-bottom: 1px solid black !important;
  }

  div[role=listitem].primary {
    border: 2px solid black !important;
    font-weight: bold !important;
    background-color: transparent !important;
  }
}
</style>

<script>
import TicketForm from './components/TicketForm.vue';
import NotificationListCard from './components/NotificationListCard.vue';
import ConfirmationDialog from './components/ConfirmationDialog.vue';
export default {
  components: {
    TicketForm,
    NotificationListCard,
    ConfirmationDialog
  },
  name: 'App',
  data() {
    return {
      ticketDialog: false,
      errorDialog: false,
      infoDialog: false,
      confirmDialog: false,
      title: 'My Swarco',
      clipped: false,
      main: [
        { title: 'Dashboard', path: '/', icon: 'home' },
        { title: 'Urenregistratie', path: '/timesheet', permission: 'CanAddHourBookings', icon: 'access_time' },
        { title: 'Urencontrole', path: '/checkbookings', permission: 'CanEditHourBookings', icon: 'mdi-check-all' },
        { title: 'Projecten', path: '/projects', permission: 'CanReadProjects', icon: 'assignment' },
        { title: 'Contracten', path: '/contracts', permission: 'CanReadProjects', icon: 'mdi-file-sign' },
        { title: 'Installaties', path: '/installations', permission: 'CanReadInstallations', icon: 'mdi-traffic-light' },
        { title: 'Producten', path: '/products', permission: 'CanEditProducts', icon: 'mdi-hexagon-multiple-outline' },
        { title: 'Voorraad', path: '/components', permission: 'CanReadComponents', icon: 'mdi-card-bulleted' },
        { title: 'Meldingen', path: '/tickets', icon: 'mdi-bell', permission: 'CanUseServiceModule' },
        { title: 'Activiteiten', path: '/activities', icon: 'mdi-calendar' },
        { title: 'Planning', path: '/capacityplanning', icon: 'mdi-chart-timeline', permission: 'CanReadHourBookings' },
        { title: 'Documentatie', path: '/documentation', permission: 'CanReadDocumentation', icon: 'mdi-help-box-outline' },
        { title: 'Personen', path: '/persons', icon: 'mdi-account' },
      ],
      management: [
        { title: 'Organisatie', path: '/administration/organization', permission: 'CanEditOrganizations', icon: 'location_city' },
        { title: 'Medewerkers', path: '/administration/employees', permission: 'CanEditEmployees', icon: 'people_outline' },
        { title: 'Resources', path: '/administration/resources', permission: 'CanEditEmployees', icon: 'mdi-floor-plan' },
        { title: 'Installatietypes', path: '/administration/installationtypes', permission: 'CanEditCategories', icon: 'mdi-tune-vertical' },
        { title: 'Bestandtypes', path: '/administration/filetypes', permission: 'CanEditCategories', icon: 'mdi-file-document-outline' },
        { title: 'Activiteiten', path: '/administration/hourbookingactivities', permission: 'CanEditCategories', icon: 'list' },
        { title: 'Meldingcategorieën', path: '/administration/ticketcategories', permission: 'CanEditCategories', icon: 'mdi-format-list-group' },
        { title: 'SLA profielen', path: '/administration/servicelevelagreementprofiles', permission: 'CanEditServiceLevelAgreementProfiles', icon: 'mdi-gauge' },
        { title: 'Checklist templates', path: '/administration/checklisttemplates', permission: 'CanEditChecklists', icon: 'mdi-playlist-check' },
        { title: 'Functieprofielen', path: '/administration/employeefunctionprofiles', permission: 'CanEditCategories', icon: 'mdi-briefcase-account-outline' },
        { title: 'IO Poort sets', path: '/administration/ioPortSets', permission: 'CanEditProducts', icon: 'mdi-swap-vertical-circle-outline' },
      ],
      advanced: [
        { title: 'Logs', path: '/advanced/logs', icon: 'list' },
        { title: 'Settings', path: '/advanced/settings', icon: 'settings' },
        { title: 'Beheer', path: '/advanced/maintenance', icon: 'mdi-wrench' },
      ],
      reports: [
        { title: 'Urenboekingen', path: '/reports/hourbookingstatus', icon: 'access_time', permission: 'CanReadHourBookings' },
        { title: 'Service Metrics', path: '/reports/servicemetrics', icon: 'mdi-finance' },
        { title: 'Verlof', path: '/reports/leave', icon: 'mdi-island' },
        { title: 'Urenkaart', path: '/reports/employee-hourbookingmatrix', icon: 'mdi-timeline-text-outline' },
        { title: 'Medewerker', path: '/reports/employee', icon: 'people_outline' },
        { title: 'Installatie', path: '/reports/installation', icon: 'mdi-traffic-light' },
        { title: 'Project', path: '/reports/project', icon: 'assignment' },
        { title: 'Klant', path: '/reports/organization', icon: 'recent_actors' },
        { title: 'Dienstoverzicht', path: '/reports/standbyduty', icon: 'mdi-account-hard-hat' }
      ],
      organizations: [],
      showPermissions: false,
      tokenExpirationTimer: null,
      showTokenAlert: false,
      tokenExpired: false,
      tokenCheckIntervalId: null
    }
  },
  watch: {
    dialogErrorCode() {
      if (this.dialogErrorCode) {
        this.errorDialog = true;
      } else {
        this.errorDialog = false;
      }
    },
    dialogMessage() {
      if (!this.dialogErrorCode && this.dialogMessage) {
        this.errorDialog = false;
        this.infoDialog = true;
      }
      if (!this.dialogErrorCode && !this.dialogMessage) {
        this.errorDialog = false;
        this.infoDialog = false;
      }
    },
    dialogConfirm(val, old) {
      if (val !== null) {
        this.confirmDialog = true;
      }
      if (val === null && old !== null) {
        this.confirmDialog = false;
      }
    }
  },
  computed: {
    dialogErrorCode() {
      return this.$root.dialogErrorCode;
    },
    dialogMessage() {
      return this.$root.dialogMessage;
    },
    dialogConfirm() {
      return this.$root.dialogConfirm;
    },
    mainWithPermission() {
      return this.checkPermissions(this.main);
    },
    managementWithPermission() {
      return this.checkPermissions(this.management);
    },
    advancedWithPermission() {
      return this.checkPermissions(this.advanced);
    },
    reportsWithPermission() {
      return this.checkPermissions(this.reports);
    },
    canCreateTickets() {
      return this.$root.myScPermissions?.some(scp => scp.canCreate);
    },
  },
  methods: {
    checkPermissions(items) {
      var that = this;
      return items.filter(function (item) {
        return item.permission === undefined || (that.$root.isAdmin || that.$root.myPermissions?.indexOf(item.permission) > -1);
      })
    },
    newTicket() {
      this.ticketDialog = false;
      this.$root.$emit('newTicket', '');
    },
    checkTokenExpiration() {
      if (!this.$root?.tokenExpire) return;
      let currentEpochTime = this.$moment().unix();
      this.tokenExpirationTimer = this.$moment.duration(this.$root?.tokenExpire - currentEpochTime, 'seconds').as('milliseconds');

      if (this.tokenExpirationTimer < 0) this.tokenExpired = true;
      if (this.tokenExpirationTimer < 300000 && !this.tokenExpired) this.showTokenAlert = true;
      else this.showTokenAlert = false;
    },
    renewToken() {
      this.$root.tokenExpire = null;
      this.$root.setToken();
      clearInterval(this.tokenCheckIntervalId);
      this.showTokenAlert = false;
      this.tokenExpired = false;
      this.setTokenCheckInterval();
    },
    setTokenCheckInterval() {
      this.tokenCheckIntervalId = setInterval(this.checkTokenExpiration, 1000); 
    },
    agreeConfirmDialog() {
      this.$root.agreeConfirmDialog();
    },
    closeConfirmDialog() {
      this.$root.cancelConfirmDialog();
    },
  },
  mounted() {
    this.$root.$on('baseOrgsLoaded', () => {
      this.organizations = [];
      this.$root.baseOrganizations.forEach(x => {
        var orgType = this.$root.organizationTypes.find(t => t.id == x.type);
        this.organizations.push({ title: orgType.name, path: '/organizations/' + x.id, icon: orgType.icon })
      });
    })
    this.setTokenCheckInterval();
  }
}
</script>