import Vue from 'vue'
import Router from 'vue-router'
import authentication from './authentication'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('./views/Home.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/hourbooking/',
      name: 'NewHourbooking',
      component: () => import('./views/Hourbooking.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/hourbooking/:id',
      name: 'Hourbooking',
      component: () => import('./views/Hourbooking.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/checkbookings',
      name: 'CheckBookings',
      component: () => import('./views/CheckBookings.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/persons',
      name: 'Persons',
      component: () => import('./views/Persons.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/organizations',
      name: 'OrganizationOverview',
      component: () => import('./views/organization/Organizations.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/organizations/:typeBaseOrgId',
      name: 'OrganizationOverviewByType',
      component: () => import('./views/organization/Organizations.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/organization/:id/:tab?',
      name: 'Organization',
      component: () => import('./views/organization/Organization.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/organization/:id/tickets/report',
      name: 'OrganizationTicketReport',
      component: () => import('./views/TicketReport.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/projects',
      name: 'Projects',
      component: () => import('./views/project/Projects.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/project/:id/:tab?',
      name: 'Project',
      component: () => import('./views/project/Project.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/project/:id/tickets/report',
      name: 'ProjectTicketReport',
      component: () => import('./views/TicketReport.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/project/:id/checklists/:checklistId/report',
      name: 'ChecklistReport',
      component: () => import('./views/ChecklistReport.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/contracts',
      name: 'Contracts',
      component: () => import('./views/project/Projects.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/contract/:id/:tab?',
      name: 'Contract',
      component: () => import('./views/project/Project.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/contract/:id/tickets/report',
      name: 'ContractTicketReport',
      component: () => import('./views/TicketReport.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/contract/:id/checklists/:checklistId/report',
      name: 'ChecklistReport',
      component: () => import('./views/ChecklistReport.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/installations',
      name: 'Installations',
      component: () => import('./views/installation/Installations.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/installation/:id/:tab?',
      name: 'Installation',
      component: () => import('./views/installation/Installation.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/installation/:id/tickets/report',
      name: 'InstallationTicketReport',
      component: () => import('./views/TicketReport.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/products',
      name: 'Products',
      component: () => import('./views/product/Products.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/product/:id',
      name: 'Product',
      component: () => import('./views/product/Product.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/productversion/:id',
      name: 'ProductVersion',
      component: () => import('./views/product/ProductVersion.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/newproductversion/:productId',
      name: 'NewProductVersion',
      component: () => import('./views/product/ProductVersion.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/productversionfile/:id',
      name: 'ProductVersionFile',
      component: () => import('./views/product/ProductVersionFile.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/newproductversionfile/:productVersionId',
      name: 'NewProductVersionFile',
      component: () => import('./views/product/ProductVersionFile.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/installationconfiguration/:installationConfigurationId/productversion/:productVersionId',
      name: 'InstallationConfigurationProductVersion',
      component: () => import('./views/installation/InstallationConfigurationProductVersion.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/installationconfiguration/:installationConfigurationId/:productVersionId/configfile/:configFileId',
      name: 'InstallationConfigurationFile',
      component: () => import('./views/installation/InstallationConfigurationFile.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/components',
      name: 'Components',
      component: () => import('./views/component/Components.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/tickets',
      name: 'Tickets',
      component: () => import('./views/tickets/Tickets.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/tickets/my',
      name: 'MyTickets',
      component: () => import('./views/tickets/Tickets.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/ticket/:id/',
      name: 'Ticket',
      component: () => import('./views/tickets/Ticket.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/administration',
      name: 'Administration',
      component: () => import('./views/Administration.vue'),
      children: [
        {
          path: 'employees',
          name: 'AdministrationEmployees',
          component: () => import('./views/administration/Employees.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: '/employee/:id',
          name: 'AdministrationEmployee',
          component: () => import('./views/administration/Employee.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'resources',
          name: 'AdministrationResources',
          component: () => import('./views/administration/Resources.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: 'organization',
          name: 'AdministrationOrganization',
          component: () => import('./views/administration/Organization.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: 'organization/:organizationId',
          name: 'AdministrationOrganizationById',
          component: () => import('./views/administration/Organization.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: 'hourbookingactivities',
          name: 'AdministrationHourBookingActivities',
          component: () => import('./views/administration/HourBookingActivities.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: 'installationtypes',
          name: 'AdministrationInstallationTypes',
          component: () => import('./views/administration/InstallationTypes.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: 'filetypes',
          name: 'AdministrationFileTypes',
          component: () => import('./views/administration/FileTypes.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: 'ticketcategories',
          name: 'AdministrationTicketCategories',
          component: () => import('./views/administration/TicketCategories.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: 'servicelevelagreementprofiles',
          name: 'AdministrationServiceLevelAgreementProfiles',
          component: () => import('./views/administration/ServiceLevelAgreementProfiles.vue'),
          meta: {
            requiresAuthentication: true,
          }
        },
        {
          path: 'checklisttemplates',
          name: 'AdministrationChecklistTemplates',
          component: () => import('./views/administration/ChecklistTemplates.vue'),
          meta: {
            requiresAuthentication: true,
          }
        },
        {
          path: 'employeefunctionprofiles',
          name: 'AdministrationEmployeeFunctionProfiles',
          component: () => import('./views/administration/EmployeeFunctionProfiles.vue'),
          meta: {
            requiresAuthentication: true,
          }
        },
        {
          path: 'ioPortSets',
          name: 'AdministrationIoPortSets',
          component: () => import('./views/administration/IoPortSets.vue'),
          meta: {
            requiresAuthentication: true,
          }
        }
      ],
      meta: {
        requiresAuthentication: true,
        role: ['Admin']
      }
    },
    {
      path: '/advanced',
      name: 'Advanced',
      component: () => import('./views/Advanced.vue'),
      children: [
        {
          path: 'logs',
          name: 'AdvancedLogs',
          component: () => import('./views/advanced/Logs.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: 'maintenance',
          name: 'AdvancedMaintenance',
          component: () => import('./views/advanced/Maintenance.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
        {
          path: 'settings',
          name: 'AdvancedSettings',
          component: () => import('./views/advanced/Settings.vue'),
          meta: {
            requiresAuthentication: true,
            role: ['Admin']
          }
        },
      ],
      meta: {
        requiresAuthentication: true,
        role: ['Admin']
      }
    },
    {
      path: '/reports',
      name: 'Reports',
      component: () => import('./views/Reports.vue'),
      children: [
        {
          path: 'servicemetrics',
          name: 'ReportServiceMetrics',
          component: () => import('./views/reports/ReportServiceMetrics.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'hourbookingstatus',
          name: 'ReportHourbookingStatus',
          component: () => import('./views/reports/HourbookingStatus.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'leave',
          name: 'leaveReport',
          component: () => import('./views/reports/Leave.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'leave/:employeeId/:periodId',
          name: 'leaveReportPeriodId',
          component: () => import('./views/reports/Leave.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'employee',
          name: 'ReportsEmployee',
          component: () => import('./views/reports/HourbookingList.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'project',
          name: 'ReportsProject',
          component: () => import('./views/reports/HourbookingList.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'installation',
          name: 'ReportsInstallation',
          component: () => import('./views/reports/HourbookingList.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'organization',
          name: 'ReportsOrganization',
          component: () => import('./views/reports/HourbookingList.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'employee-hourbookingmatrix',
          name: 'EmployeeHourBookingMatrix',
          component: () => import('./views/reports/EmployeeHourBookingMatrix.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'standbyduty',
          name: 'StandbyDutyOverview',
          component: () => import('./views/reports/StandbyDutyOverview.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
        {
          path: 'standbyduty/:year/:type',
          name: 'StandbyDutyOverviewByYearAndType',
          component: () => import('./views/reports/StandbyDutyOverview.vue'),
          meta: {
            requiresAuthentication: true
          }
        },
      ],
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/timesheet',
      name: 'Timesheet',
      component: () => import('./views/Timesheet.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/timesheet/:week',
      name: 'TimesheetByWeek',
      component: () => import('./views/Timesheet.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/timesheet/:week/:day',
      name: 'TimesheetByDay',
      component: () => import('./views/Timesheet.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('./views/About.vue'),
      meta: {
        requiresAuthentication: false
      }
    },
    {
      path: '/accessdenied',
      name: 'AccessDenied',
      component: () => import('./views/AccessDenied.vue'),
    },
    {
      path: '/activities',
      name: 'Activities',
      component: () => import('./views/Activities.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/activities/:week',
      name: 'ActivitiesByWeek',
      component: () => import('./views/Activities.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/capacityplanning',
      name: 'CapacityPlanning',
      component: () => import('./views/CapacityPlanning.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/capacityplanning/:week',
      name: 'CapacityPlanningByWeek',
      component: () => import('./views/CapacityPlanning.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/documentation/',
      name: 'Documentation',
      component: () => import('./views/Documentation.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/documentation/:pageId',
      name: 'DocumentationById',
      component: () => import('./views/Documentation.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
    {
      path: '/preferences/',
      name: 'Preferences',
      component: () => import('./views/Preferences.vue'),
      meta: {
        requiresAuthentication: true
      }
    },
  ]
})

// Global route guard
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    // this route requires auth, check if logged in
    if (authentication.isAuthenticated()) {
      // only proceed if authenticated.
      next()
    } else {
      authentication.signIn()
    }
  } else {
    next();
  }
})

export default router